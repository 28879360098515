import { writable, get, type Writable, type Readable } from "svelte/store"
import type { CustomPageOptions } from "./types"
import type { Page } from "@sveltejs/kit"
import { page } from "$app/stores"

// Stores
export const pageOptionsStore: Writable<{
  headerFixed: boolean
  headerModal: boolean
  headerLanding: boolean
  footerVisible: boolean
  footerLanding: boolean
  applyOrientationModalVisible: boolean
  bodyStyle: "gray" | undefined
  path: string | undefined
  referrer: string | undefined
  modalCloseCallback: (() => Promise<boolean>) | undefined
}> = writable({
  headerFixed: true,
  headerModal: false,
  headerLanding: false,
  footerVisible: true,
  footerLanding: false,
  applyOrientationModalVisible: true,
  bodyStyle: undefined,
  path: undefined,
  referrer: undefined,
  modalCloseCallback: undefined,
})

// Page utils
export const customPageOptions = (opts: CustomPageOptions, pageParam?: Readable<Page>) => {
  const currentPage = pageParam ? pageParam : page
  const { url } = get(currentPage)

  const pageOptions = { ...get(pageOptionsStore) }

  pageOptionsStore.set({
    ...pageOptions,
    headerFixed: opts.headerFixed ?? pageOptions.headerFixed,
    headerModal: opts.headerModal ?? pageOptions.headerModal,
    headerLanding: opts.headerLanding ?? pageOptions.headerLanding,
    footerVisible: opts.footerVisible ?? pageOptions.footerVisible,
    applyOrientationModalVisible: opts.applyOrientationModalVisible ?? pageOptions.applyOrientationModalVisible,
    footerLanding: opts.footerLanding ?? pageOptions.footerLanding,
    bodyStyle: opts.bodyStyle ?? pageOptions.bodyStyle,
    path: url.pathname,
    referrer: opts.referrer ?? pageOptions.referrer,
    modalCloseCallback: opts.modalCloseCallback ?? pageOptions.modalCloseCallback,
  })
}
